import { Helmet } from "react-helmet";

const AdvancedStats = () => {
  return (
    <section className="section about-section">
      <Helmet>
        <title>About</title>
      </Helmet>
      <header className="tc ph4">
        <h1>A Fresh Perspective</h1>
      </header>
      <div className="tc ph4">
        <p className="text">
          Drives markets deliver powerful insights that traditional stats miss.
          Track momentum shifts, possession efficiency, and scoring patterns as
          they unfold in real-time.
        </p>
        <p className="text">
          <strong>Drives Score (DS)</strong> - represents the situational value
          (i.e. down/distance) in game scoring terms – it’s the foundation for
          our suite of advance metrics.
        </p>
        <p className="text">
          <strong>Drives Score Added (DSA) </strong> - measures how much each
          play changes the offense’s scoring prospects. Puts values to every
          facet of the game – e.g. takeaways, sacks, penalties and much more.
        </p>
        <p className="text">
          <strong>Clutch Score (CS)</strong> – The <strong>Gamechanger</strong>,
          adjusts DSA to highlight game-changing plays. Identifies each week’s
          <strong> Heros & Zeros</strong>.
        </p>
        <p className="text">
          <strong>Drives Impact Score (DIS)</strong> – The{" "}
          <strong>Garbage Meter;</strong> boosts meaningful plays to separate
          them from "garbage time" stat padding.
        </p>
        <p className="text">
          <strong>Drives Impact Ratio (DIR)</strong> – A{" "}
          <strong>Blowout Rating;</strong> measures a team’s overall performance
          during competitive play versus blowout situations.
        </p>
      </div>

      <header className="tc ph4">
        <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
          Pre-Game Analysis
        </h2>
      </header>

      <div className="tc ph4">
        <p className="text text-center w-full">
          Dissects team strengths, player matchups, and situational advantages
          for a winning edge.
        </p>
        <p className="text">
          <strong>Reality Check</strong> – are the box score stats “real”?
        </p>
        <p className="text">
          <strong>Scoring DNA</strong> – what’s really driving scoring?
        </p>
        <p className="text">
          <strong>Matchup Scouting</strong> – ignore the chatter, find the
          advantages oddsmakers miss.
        </p>
        <p className="text">
          <strong>Regression Alerts</strong> – are seasonal strengths
          sustainable?
        </p>
        <p className="text">
          <strong>Fluke Factor</strong> – has a team been good or just lucky?
        </p>
        <p className="text">
          <strong>Winning Mentality</strong> – does a team know how to win or
          just lose in style?
        </p>
        <p className="text">
          <strong>Breakouts</strong> – identify opportunities for best bets or
          parlays.
        </p>
      </div>

      <header className="tc ph4">
        <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">In-Game</h2>
      </header>

      <div className="tc ph4">
        <p className="text">
          Compare season trends with game action to identify in-game and prop
          opportunities.
        </p>
      </div>

      <header className="tc ph4">
        <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
          Example Wagers
        </h2>
      </header>

      <div className="tc ph4">
        <p className="text">
          <strong>Garbage Meter:</strong> With a 72% Garbage Meter rating,
          traditional stats overestimate the NY Jets’ offensive production. Take
          Miami -3.
        </p>
        <p className="text">
          <strong>Blowout Rating:</strong> Detroit’s defense has an 83% Blowout
          Rating, meaning opponents pile up meaningless late-game stats and
          scores. Against a tough Houston team, blowout scoring should be
          limited. Take the Under.
        </p>
        <p className="text">
          <strong>Reality Check:</strong> Josh Allen’s low interception DSA
          totals shows he’s been strategically aggressive, not reckless. Against
          a strong defense, Josh will reign in the turnovers. Take the Bills -5.
        </p>
        <p className="text">
          <strong>Matchup Scouting</strong> Facing the Raiders' penalty-prone
          secondary, expect Cincy's deep threats to exceed already lofty
          expectations thanks to penalty-driven DSA scoring. Take Cincinnati -8.
        </p>
        <p className="text">
          <strong>Regression Alerts:</strong> Exceptional field position has
          boosted Rams DSA scoring by almost 4 points per game. With key
          defensive injuries, LA’s field position should revert to league
          average. Take Bucs +4.
        </p>
        <p className="text">
          <strong>In-Game:</strong> The Patriots continue their pattern of
          trailing at the half. If trends hold, Maye will mount a frenzied
          comeback, that falls short yet again. Take the Pat’s +11.
        </p>
        <p className="text">
          This is just a glimpse of our analytical edge. Drives is committed to
          developing cutting-edge metrics in collaboration with content and
          gaming partners.
        </p>
      </div>
    </section>
  );
};

export default AdvancedStats;
